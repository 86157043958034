<template lang="pug">
article.route.route--full-page.events
    .bg-container
        picture
            source(media="(min-width:1024px)", srcset="@/assets/images/events/bg.webp")
            img.bg-image(src="@/assets/images/events/bgSmall.webp")
    .content
        section.section
            .section-container
                h1.h2.tac {{ $t('events.headline') }}
                hr.sechr
                EventList(v-if="!eventsDataLoading" :events-data="eventsData" :events-shown="24")

</template>

<script>
// @ is an alias to /src
import EventList from '@/components/EventList'
import getEvents from '@/js/mixins/getEvents.js'


export default {
    name: 'Events',
    components: {
        EventList,
    },
    mixins: [getEvents],
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';
.bg-container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 740px;
    width: 100%;

    @include at(mobileLarge) {
        height: 940px;
    }

    @include at(laptop) {
        height: 100%;
    }
}

.section-container {
    min-height: 100vh;
}


.sechr {
    border: 0;
    border-top: 1px solid $contentBg-bright;
    opacity: .5;
    margin: 0 0 24px 0;
}
</style>
